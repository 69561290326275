import {
    Box,
    Card,
    Grid,
    InputLabel,
    TextField,
  } from "@mui/material";
  import * as Yup from "yup";
  import { useState } from "react";
  import { useTranslation } from "react-i18next";
  import { Form, FormikProvider, useFormik } from "formik";
  import { DistributorsService } from "../../../../api/services";
  import BaseSnackbar from "../../../../components/BaseSnackbar";
  import SaveIcon from "@mui/icons-material/Save";
  import { LoadingButton } from "@mui/lab";
  import { useTheme } from "@mui/material/styles";
  import { useNavigate } from "react-router-dom";
  
  export default function AddDistributor() {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
  
    const DistributorSchema = Yup.object().shape({
      distributorName: Yup.string()
        .required(t("distributor-name-is-required"))
        .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
        .test(
          "has-more-letters-than-commas-and-periods",
          t("name-must-have-more-letters-than-commas-and-periods"),
          (value) => {
            if (!value) {
              return true;
            }
            const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
            const specialCharsCount = (value.match(/[,./]/g) || []).length;
            return lettersCount > specialCharsCount;
          }
        ),
      distributorID: Yup.number().required(t("distributor-id-is-required")),
    });
  
    const formik = useFormik({
      validationSchema: DistributorSchema,
      initialValues: {
        distributorName: "",
        distributorID: "",
      },
      onSubmit: (values, actions) => {
        const payload = {
          id: values.distributorID,
          name: values.distributorName,
        };
  
        let successMessage = t("new-distributor-added-successfully");
        let failMessage = t("distributor-add-fail");
  
        DistributorsService.createDistributor(payload)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              setTimeout(() => {
                navigate("/distributor/list", {
                  state: {
                    successMessage: successMessage,
                  },
                });
              }, 600);
  
              actions.setSubmitting(false);
            } else {
              throw new Error("failed to create distributor");
            }
          })
          .catch((err) => {
            console.error(err);
            setMessage(failMessage);
            setSnackbarStatus(true);
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          });
      },
    });
  
  
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  
    return (
      <>
        <BaseSnackbar
          open={openSnackbar}
          message={message}
          setOpen={setSnackbarStatus}
        />
  
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 5,
                gap: 4,
                mt: 5,
              }}
            >
              <Grid container spacing={10}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputLabel>{t("distributor-name")}<span style={{color:"red"}}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="distributorName"
                    label={t("distributor-name")}
                    placeholder={t("enter-distributor-name")}
                    {...getFieldProps("distributorName")}
                    error={Boolean(touched.distributorName && errors.distributorName)}
                    helperText={touched.distributorName && errors.distributorName}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputLabel>{t("distributor-id")}<span style={{color:"red"}}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="distributorID"
                    label={t("distributor-id")}
                    placeholder={t("enter-distributor-id")}
                    {...getFieldProps("distributorID")}
                    error={Boolean(touched.distributorID && errors.distributorID)}
                    helperText={touched.distributorID && errors.distributorID}
                  />
                </Grid>
              </Grid>
  
              <Box className="add-distributor-button" sx={{
                width: "20%",
                alignSelf: "center"
              }}>
                <LoadingButton
                  sx={{ mt: 3, backgroundColor: theme.palette.accent.main }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  startIcon={<SaveIcon />}
                >
                  {t("add-distributor")}
                </LoadingButton>
              </Box>
            </Card>
          </Form>
        </FormikProvider>
      </>
    );
  }
  